<template>
  <div class="container">
    <TextArea/>
    <TimeCountdown/>
    <p>Emergency? Call us at <br><a href="tel:+46766866816">+46 (0)766 86 68 16</a></p>
  </div>
</template>

<script>
import TextArea from "@/components/TextArea";
import TimeCountdown from "@/components/TimeCountdown";

export default {
  name: 'App',
  components: {
    TimeCountdown,
    TextArea,
  }
}
</script>

<style lang="scss">
body, html {
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  font-size: 22px;
  min-height: 100vh;
  background-image: url("@/assets/background.jpg");
  background-size: cover;
  box-sizing: border-box;
  padding: 25px;
  position: relative;

  .container {
    max-width: 900px;
  }

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  }
  >div {
    z-index: 1;
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 2px dotted #fff;
    transition: 0.2s;

    &:focus,
    &:hover {
      border-bottom: 2px dotted #78c0c0;
      color: #78c0c0;
    }
  }
}
</style>
