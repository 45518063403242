<template>
  <img src="@/assets/acme.png" alt="ACME Business" width="" height="" loading="lazy"/>
  <h1>Business Service Support</h1>
  <p>The multi-channel customer-centric service support for your company.</p>
  <p>Website launching 2015!</p>
</template>

<script>
export default {
  name: "TextArea"
}
</script>

<style scoped lang="scss">
h1 {
  padding: 0;
  margin: 0;
}
</style>
