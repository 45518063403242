<template>
  <div class="countdowns">
    <div class="countdown">
      <p class="number">{{ daysFormated }}</p>
      <p class="label">days</p>
    </div>
    <div class="countdown">
      <p class="number">{{ hoursFormated }}</p>
      <p class="label">hours</p>
    </div>
    <div class="countdown">
      <p class="number">{{ minutesFormated }}</p>
      <p class="label">minutes</p>
    </div>
    <div class="countdown">
      <p class="number">{{ secondsFormated }}</p>
      <p class="label">seconds</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeCountdown",
  data() {
    return {
      days: 1000,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timeout: null
    }
  },
  computed: {
    daysFormated() {
      return this.days;
    },
    hoursFormated() {
      return this.hours;
    },
    minutesFormated() {
      return this.minutes;
    },
    secondsFormated() {
      return this.formated(this.seconds);
    },
  },
  methods: {
    formated(num) {
      return String(num.toString()).padStart(2, '0')
    },
    decreaseSeconds() {
      if (this.seconds <= 0) {
        this.seconds = 59;
        if (this.minutes <= 0) {
          this.minutes = 59;
          if (this.hours <= 0) {
            this.hours = 23;
            this.days--;
          } else {
            this.hours--;
          }
        } else {
          this.minutes--;
        }
      } else {
        this.seconds--;
      }
      this.timeout = setTimeout(() => this.decreaseSeconds(), 1000);
    }
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    this.days = (2015 - currentYear) * 365;
    // this.hours = Math.floor(Math.random() * 25);
    // this.minutes = Math.floor(Math.random() * 60);
    this.seconds = Math.floor(Math.random() * 60);
    this.timeout = setTimeout(() => this.decreaseSeconds(), 1000);
  }
}
</script>

<style scoped lang="scss">
.countdowns {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;

  .countdown {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 3px;
    align-items: end;
    justify-content: center;

    border: 1px solid #fff;
    padding: 5px 15px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);

    p {
      padding: 0;
      margin: 0;

      &.number {
        font-size: 25px;
        line-height: 25px;
      }

      &.label {
        font-size: 11px;
        text-transform: uppercase;

      }
    }
  }
}
</style>
